import React, { Component } from "react";
import Navbar from "../../components/navbar";
import * as projectStyles from "../../styles/css/project.module.css";
import ads_screen from "../../styles/images/adsBis.png";
import ads_product from "../../styles/images/ads.png";
import ads_services from "../../styles/images/adsServices.png";
import ads_mobile from "../../styles/images/adsMobile.png";
import ads_banner from "../../styles/images/adsBanner.png";

class Ads extends Component {
    render(){
        return (
            <React.Fragment>
                <Navbar color="white"/>
                <div>
                    <div className={projectStyles.back}>
                        <img src={ads_banner}></img>
                    </div>
                    <div className={projectStyles.banner}>
                        <div>
                            <span style={{fontStyle:"italic"}}>Refonte du site vitrine ads53.com</span>
                            <h1 style={{fontSize: "6em", textTransform:"uppercase"}}>ADS</h1>
                            <span style={{color:"#DCDCDC", textTransform:"uppercase", fontSize: "0.8em"}}>Type de projet</span>
                            <span style={{fontSize: "0.8em"}}>Site vitrine</span>
                        </div>
                    </div>
                    <div style={{background:"white"}}>
                    <div className={projectStyles.wrapper}>
                        <div className={projectStyles.container}>
                            <div>
                                <ul className={projectStyles.details}>
                                    <li>Client</li>
                                    <li> ADS</li>
                                </ul>
                                <ul className={projectStyles.details}>
                                    <li>Projet</li>
                                    <li>Refonte du site vitrine de l'entreprise</li>
                                </ul>
                                <ul className={projectStyles.details}>
                                    <li>Date</li>
                                    <li>04/20</li>
                                </ul>
                            </div>
                            <div>
                                <p>
                                    ADS (Automatisme Desmot Service), TPE mayennaise dans le secteur de l'automastime et de l'accessibilité nous a confié
                                    la refonte de son site vitrine. Le souhait du dirigeant était de moderniser son image digitale afin que 
                                    celle-ci représente le plus fidèlement son activité.
                                </p>
                                <p>
                                    Ce projet a été réalisé sous Wordpress afin de permettre au client de modifier le contenu de son site en toute
                                    simplicité.
                                </p>
                                <a href="https://ads53.com/"  target="_blank" rel="noreferrer" onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>Voir le site</a>
                            </div>
                        </div>
                        <img src={ads_screen} className={projectStyles.example} alt={"Page d'accueil du site ads53.com"}></img>
                        <img src={ads_services} className={projectStyles.example} alt={"Page services du ads53.com"}></img>
                        <div className={projectStyles.mobile}>
                            <img src={ads_product} className={projectStyles.product} alt={"Page taille ordinateur d'un produit du site ads53.com"}></img>
                            <img src={ads_mobile} className={projectStyles.productMobile} alt={"Page taille mobile d'un produit du site ads53.com"}></img>
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment>  
        )
    }
}

export default Ads;